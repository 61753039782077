import { useRef, useState } from "react";

import { Box, Center, IconButton, Text } from "@chakra-ui/react";
import axios from "axios";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { TbBrandLinkedin, TbFileText, TbSend } from "react-icons/tb";

import { AdminFullAthleteProfileInfo } from "../../../../../../shared/models";

enum resumeStatus {
  empty,
  pending,
  ready,
  error,
}

interface ResumeData {
  url: string | null;
  status: resumeStatus;
}

export const ActionButtons: React.FC<AdminFullAthleteProfileInfo> = (data: AdminFullAthleteProfileInfo) => {
  const { linkedInUrl, readyToWork, resumeMeta, isPending, emailAddress } = data;
  const [resumeData, setResumeData] = useState<ResumeData>({ url: null, status: resumeStatus.empty });
  const actionColumnRef = useRef<HTMLDivElement>(null);

  let dynamicIconSize = 32;
  if (actionColumnRef.current) {
    const width = actionColumnRef.current.getBoundingClientRect().width;
    const adjustment = Math.min(10, Math.round((width - 100) / 10));
    dynamicIconSize = Math.max(22, 22 + adjustment);
  }

  const preloadResumeUrl = async () => {
    if (resumeMeta && !resumeData.url) {
      setResumeData({ ...resumeData, status: resumeStatus.pending });
      try {
        const url = await axios.post("/api/file/presigned-open-url", {
          category: "resume",
          remoteFileName: resumeMeta.stored_file_name,
        });
        setResumeData({ url: url.data.presignedUrl, status: resumeStatus.ready });
      } catch (error) {
        setResumeData({ ...resumeData, status: resumeStatus.error });
      }
    }
  };

  const handleResumeClick = async () => {
    if (!resumeMeta || resumeData.status === resumeStatus.error) {
      return;
    }
    if (!resumeData.url && resumeData.status === resumeStatus.pending) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
    if (resumeData.url && resumeData.status === resumeStatus.ready) {
      window.open(resumeData.url, "_blank", "noopener,noreferrer");
    }
  };

  if (isPending) {
    return (
      <Center>
        <Text size={"sm"} textAlign={"center"}>
          Pending
        </Text>
      </Center>
    );
  }

  return (
    <Box
      display={"flex"}
      width={"full"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onMouseEnter={preloadResumeUrl}
      ref={actionColumnRef}
    >
      <IconButton
        icon={<TbFileText fontSize={dynamicIconSize} />}
        aria-label="Resume"
        isDisabled={!resumeMeta || resumeData.status === resumeStatus.error}
        variant="ghost"
        color={resumeMeta ? "blue.500" : "gray.300"}
        onClick={handleResumeClick}
      />
      <IconButton
        as="a"
        icon={<TbBrandLinkedin fontSize={dynamicIconSize} />}
        aria-label="LinkedIn profile"
        target="_blank"
        rel="noopener noreferrer"
        href={linkedInUrl ? (linkedInUrl.startsWith("http") ? linkedInUrl : `https://${linkedInUrl}`) : undefined}
        isDisabled={!linkedInUrl}
        variant="ghost"
        color={linkedInUrl ? "blue.500" : "gray.300"}
      />
      <IconButton
        icon={<TbSend fontSize={dynamicIconSize - 2} />}
        aria-label="Send Email"
        isDisabled={!emailAddress}
        variant="ghost"
        color={emailAddress ? "blue.500" : "gray.300"}
        onClick={() => {
          if (emailAddress) {
            window.location.href = `mailto:${emailAddress}`;
          }
        }}
      />
      {readyToWork ? (
        <BsCheck2Circle fontSize={dynamicIconSize} style={{ color: "green" }} />
      ) : (
        <BsCircle fontSize={dynamicIconSize} style={{ color: "red" }} />
      )}
    </Box>
  );
};
