import { useEffect, useState } from "react";

import {
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Flex,
  ModalFooter,
  Button,
  useToast,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { AwesomeHCASpinner } from "./AwesomeHCASpinner";
import { useAppStore } from "../app-store";
import { createOrUpdateConversation, listenToUserConversations, userListeners } from "../services/firebase-service";
import { FullConversation, MessagesApi } from "../services/messages-api";

// TODO: I think this should be participant info and include both ids and names.
interface RecipientInfo {
  recipientId: number;
  recipientName: string;
}

interface MessagingModalProps {
  isOpen: boolean;
  onClose: () => void;
  recipient: RecipientInfo;
  conversation: FullConversation | null;
}

export default function MessagingModal({ isOpen, onClose, recipient, conversation }: MessagingModalProps) {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [activeConversation, setActiveConversation] = useState<FullConversation | null>(null);
  const [newMessage, setNewMessage] = useState("");
  const toast = useToast();

  const {
    data: conversationData,
    isLoading: isConversationLoading,
    refetch: fetchConversation,
  } = useQuery({
    queryKey: ["conversation", conversation?.id],
    queryFn: () => MessagesApi.getConversation(conversation?.id || ""),
    enabled: !!conversation?.id,
  });

  useEffect(() => {
    if (!isOpen) return;
    if (conversation?.messages.length && conversation?.messages.length > (activeConversation?.messages.length || 0)) {
      setActiveConversation(conversation);
    }
    if (!conversation) {
      setActiveConversation(null);
    }
  }, [conversation, isOpen]);

  useEffect(() => {
    if (activeConversation) {
      if (!userListeners.get(activeConversation.id)) {
        listenToUserConversations(userInfo!.id, fetchConversation);
      }
      if (activeConversation.messages.some((message) => !message.is_read && message.sender_id !== userInfo?.id)) {
        updateConversationReadStatus();
      }
    }
  }, [activeConversation]);

  useEffect(() => {
    if (conversationData && conversationData.messages.length > (activeConversation?.messages.length || 0)) {
      setActiveConversation(conversationData);
    }
  }, [conversationData]);

  const { mutate: createConversation, isPending: isCreatingConversation } = useMutation({
    mutationFn: () => MessagesApi.createConversation(userInfo!.id, Number(recipient.recipientId), newMessage),
    onSuccess: (data: FullConversation) => {
      setActiveConversation(data);
      setNewMessage("");
      createOrUpdateConversation(data.id, userInfo!.id, Number(recipient.recipientId));
    },
    onError: (error) => {
      console.error("Error creating conversation:", error);
      toast({
        title: "Error",
        description: "There was an issue creating the conversation",
        status: "error",
        position: "top",
        duration: 3000,
      });
    },
  });

  const { mutate: addMessage } = useMutation({
    mutationFn: () => MessagesApi.addMessage(activeConversation!.id, newMessage),
    onSuccess: (data: FullConversation) => {
      setActiveConversation(data);
      setNewMessage("");
      createOrUpdateConversation(data.id, userInfo!.id, Number(recipient.recipientId));
    },
    onError: (error) => {
      console.error("Error adding message:", error);
      toast({
        title: "Error",
        description: "There was an issue adding the message",
        status: "error",
        position: "top",
        duration: 3000,
      });
    },
  });

  // update the conversation to mark the messages as read for this user
  const { mutate: updateConversationReadStatus } = useMutation({
    mutationFn: () => MessagesApi.markConversationMessagesAsRead(activeConversation!.id, userInfo!.id),
    onSuccess: () => {
      createOrUpdateConversation(activeConversation!.id, userInfo!.id, Number(recipient.recipientId));
    },
  });

  const handleSendMessage = () => {
    if (!activeConversation) {
      createConversation();
    } else {
      addMessage();
    }
  };

  const handleClose = () => {
    setNewMessage("");
    setActiveConversation(null);
    onClose();
  };

  const otherParticipant = activeConversation?.participants.find((participant) => participant.user_id !== userInfo?.id);
  const recipientName = recipient.recipientName || otherParticipant?.displayName || "...";
  let headerText = recipientName;
  switch (otherParticipant?.user.role) {
    case "admin":
      headerText = `${headerText} at Her Competitive Advantage`;
      break;
    case "company_admin":
    case "recruiter":
      headerText = otherParticipant?.companyName ? `${headerText} at ${otherParticipant?.companyName}` : headerText;
      break;
    case "athlete":
      headerText = otherParticipant?.universityName
        ? `${headerText} at ${otherParticipant?.universityName}`
        : headerText;
      break;
  }

  const sortedMessages = activeConversation?.messages.sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  // const lastReadMessageId = activeConversation?.messages
  //   .filter((message) => message.is_read && message.sender_id === userInfo?.id)
  //   .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]?.id;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="60vw" minWidth="400px">
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" padding={3}>
          {isCreatingConversation ? (
            <Flex flex={1} justifyContent="center" alignItems="center">
              <AwesomeHCASpinner />
            </Flex>
          ) : (
            <Flex flex={1} flexDirection="column" gap={4} justifyContent="space-between" height="full">
              <VStack
                flex={1}
                maxHeight="50vh"
                overflowY="auto"
                justifyContent="flex-start"
                padding={2}
                ref={(el) => {
                  if (el) {
                    el.scrollTop = el.scrollHeight;
                  }
                }}
              >
                {sortedMessages?.map((message, index) => {
                  const messageDate = new Date(message.created_at);
                  const today = new Date();
                  const timeString =
                    messageDate.toDateString() === today.toDateString()
                      ? messageDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
                      : null; // messageDate.toLocaleDateString([], { month: "short", day: "numeric" });
                  return (
                    <Flex
                      key={message.id}
                      width="full"
                      flexDirection="column"
                      alignItems={message.sender_id === userInfo?.id ? "flex-end" : "flex-start"}
                    >
                      {index > 0 &&
                        new Date(message.created_at).toDateString() !==
                          new Date(sortedMessages[index - 1].created_at).toDateString() && (
                          <>
                            <Text fontSize="md" color="gray.900" mt={2}>
                              {new Date(message.created_at).toLocaleDateString([], {
                                weekday: "long",
                                month: "long",
                                day: "numeric",
                              })}
                            </Text>
                            <Divider my={2} borderColor="gray.300" />
                          </>
                        )}
                      <Text fontSize="xs" color="gray.500">
                        {message.sender_id === userInfo?.id ? "Me" : recipientName}{" "}
                        {timeString ? `- ${timeString}` : ""}
                      </Text>
                      <Flex
                        bg={message.sender_id === userInfo?.id ? "pink.200" : "gray.100"}
                        maxWidth="80%"
                        rounded="lg"
                        padding={2}
                      >
                        <Text>{message.content}</Text>
                      </Flex>
                      {/* {message.id === lastReadMessageId && (
                        <Text fontSize="xs" color="gray.500">
                          Seen
                        </Text>
                      )} */}
                    </Flex>
                  );
                })}
                {isConversationLoading && (
                  <Flex flex={1} justifyContent="center" alignItems="center">
                    <AwesomeHCASpinner />
                  </Flex>
                )}
              </VStack>
              <Flex width="full">
                <Textarea
                  placeholder="Type your message here..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </Flex>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter pt={0} pr={3}>
          <Button onClick={handleSendMessage} isDisabled={!newMessage || newMessage.trim() === ""}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
