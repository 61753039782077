import axios from "axios";

import { AppConfig } from "./app-config-service";
import { MessageParticipant } from "../../../server/db/schema";

export interface Conversation {
  id: string;
  created_at: Date;
  updated_at: Date;
  last_message_at: Date;
  deleted_at: Date | null;
}

export interface ConversationMessage {
  id: number;
  conversation_id: string;
  sender_id: number;
  content: string;
  created_at: Date;
  is_read: boolean;
  deleted_at: Date | null;
}

export interface ConversationParticipant {
  id: number;
  conversation_id: string;
  user_id: number;
  last_read_message_id: number | null;
}

export interface FullConversation extends Conversation {
  participants: MessageParticipant[];
  messages: ConversationMessage[];
}

export const MessagesApi = {
  // Create a new conversation
  createConversation: async (
    senderId: number,
    recipientId: number,
    initialMessage: string,
  ): Promise<FullConversation> => {
    const response = await axios.post(`${AppConfig.apiUrl}/api/conversations`, {
      senderId,
      recipientId,
      initialMessage,
    });
    return response.data;
  },

  // Get a specific conversation with all messages and participants
  getConversation: async (conversationId: string): Promise<FullConversation> => {
    const response = await axios.get(`${AppConfig.apiUrl}/api/conversations/${conversationId}`);
    return response.data;
  },

  // Get all conversations for the current user
  getUserConversations: async (userId: number): Promise<FullConversation[]> => {
    const response = await axios.get(`${AppConfig.apiUrl}/api/conversations/user/${userId}`);
    return response.data;
  },

  getConversationByParticipants: async (senderId: number, recipientId: number): Promise<FullConversation | null> => {
    if (!senderId || !recipientId) return null;
    const response = await axios.get(
      `${AppConfig.apiUrl}/api/conversations/sender/${senderId}/recipient/${recipientId}`,
    );
    return response.data;
  },

  // Add a new message to a conversation
  addMessage: async (conversationId: string, content: string): Promise<FullConversation> => {
    const response = await axios.post(`${AppConfig.apiUrl}/api/messages`, { conversationId, content });
    return response.data;
  },

  // Mark a message as read
  markConversationMessagesAsRead: async (conversationId: string, userId: number): Promise<void> => {
    await axios.patch(`${AppConfig.apiUrl}/api/conversations/${conversationId}/user/${userId}/read`);
  },

  // Soft delete a conversation
  deleteConversation: async (conversationId: string): Promise<void> => {
    await axios.delete(`${AppConfig.apiUrl}/api/conversations/${conversationId}`);
  },

  // Soft delete a message
  deleteMessage: async (messageId: number): Promise<void> => {
    await axios.delete(`${AppConfig.apiUrl}/api/messages/${messageId}`);
  },

  // Get unread message count for a conversation
  getUnreadMessageCount: async (conversationId: string): Promise<number> => {
    const response = await axios.get(`${AppConfig.apiUrl}/api/conversations/${conversationId}/unread`);
    return response.data.count;
  },
};
