import { onValue, ref, update, set, get, off } from "firebase/database";
import { Timestamp } from "firebase/firestore";

import { database } from "../firebase";

const userListeners = new Map();

// FIXME: I need to setup better security rules for the database.

const createOrUpdateConversation = async (conversationId: string, senderId: number, receiverId: number) => {
  const timestamp = Timestamp.now();

  try {
    const conversationRef = ref(database, `conversations/${conversationId}`);

    // Check if the conversation already exists
    const snapshot = await get(conversationRef);
    if (snapshot.exists()) {
      // Update the timestamp
      await update(conversationRef, { lastUpdated: timestamp });
    } else {
      // Create a new conversation record
      await set(conversationRef, { lastUpdated: timestamp });
      await addOrUpdateUserConversations(senderId, conversationId);
      await addOrUpdateUserConversations(receiverId, conversationId);
    }
  } catch (error) {
    console.error("Error creating or updating conversation:", error);
  }
};

const addOrUpdateUserConversations = async (userId: number, conversationId: string) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const conversations = snapshot.val().conversations || [];
      if (!conversations.includes(conversationId)) {
        conversations.push(conversationId);
        await update(userRef, { conversations });
      }
    } else {
      await set(userRef, { conversations: [conversationId] });
    }
  } catch (error) {
    console.error("Error adding or updating user:", error);
  }
};

const listenToUserConversations = async (
  userId: number,
  onConversationUpdate?: () => void,
  onConversationsUpdated?: () => void,
  onUserCreated?: () => void,
) => {
  try {
    const userRef = ref(database, `users/${userId}`);

    // Listen to changes in the user's conversations
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const { conversations } = snapshot.val();

        // Add listeners for new conversations and remove listeners for removed ones
        conversations.forEach((conversationId: string) => {
          if (!userListeners.has(conversationId)) {
            const conversationRef = ref(database, `conversations/${conversationId}/lastUpdated`);

            // Create a listener for this conversation and store it in the map
            const listener = onValue(conversationRef, (snapshot) => {
              if (snapshot.exists()) {
                if (onConversationUpdate) {
                  onConversationUpdate();
                }

                if (onConversationsUpdated) {
                  onConversationsUpdated();
                }
              }
            });

            // Store the conversation's listener reference in the Map
            userListeners.set(conversationId, { conversationRef, listener });
          }
        });

        // Remove listeners for conversations that are no longer in the user's list
        for (const [conversationId, { conversationRef }] of userListeners.entries()) {
          if (!conversations.includes(conversationId)) {
            off(conversationRef); // Remove the Firebase listener
            userListeners.delete(conversationId); // Remove from the Map
          }
        }
      } else {
        if (onUserCreated) {
          listenToUsers(userId, onUserCreated);
        }
      }
    });

    // Return the map of listeners for cleanup
    return userListeners;
  } catch (error) {
    console.error("Error setting up conversation listeners:", error);
    return new Map();
  }
};

const listenToUsers = async (userId: number, onUserCreated?: (userId: number) => void) => {
  const allUsersRef = ref(database, `users`);
  onValue(allUsersRef, (snapshot) => {
    if (snapshot.exists()) {
      const users = snapshot.val();
      if (!users[userId]) {
        onUserCreated?.(userId);
      }
    }
  });
};

export { createOrUpdateConversation, addOrUpdateUserConversations, listenToUserConversations, userListeners };
