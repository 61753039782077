import { useEffect, useRef, useState } from "react";

import { Flex, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { TbFileText, TbBrandLinkedin, TbSend, TbMessageCircle } from "react-icons/tb";

import MessagingModal from "./MessagingModal";
import { useDebouncedResize } from "../hooks/useDebounceResize";
import { ConversationInfo } from "../pages/find-athletes/components/AthleteFocusCard";

interface AthleteQuickActionsProps {
  conversationInfo: ConversationInfo | null;
  resumeOpenUrl: string;
  linkedInUrl: string;
  emailAddress: string;
  readyToWork: boolean;
  direction?: "row" | "column";
  baseIconSize?: number;
}

export const AthleteQuickActions = ({
  conversationInfo,
  resumeOpenUrl,
  linkedInUrl,
  emailAddress,
  readyToWork,
  direction = "row",
  baseIconSize = 8,
}: AthleteQuickActionsProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const ref = useRef<HTMLDivElement>(null);
  const [dynamicIconSize, setDynamicIconSize] = useState(isMobile ? 24 : 32);
  const [isMessagingModalOpen, setIsMessagingModalOpen] = useState(false);
  useEffect(() => {
    getDynamicIconSize();
  }, []);

  const getDynamicIconSize = () => {
    if (!ref.current) return;
    const iconBoxDimensions = ref.current?.getBoundingClientRect();
    let dynamicIconSize =
      baseIconSize + Math.floor((direction === "row" ? iconBoxDimensions?.width : iconBoxDimensions?.height) * 0.1);
    dynamicIconSize = Math.max(dynamicIconSize, 16);
    dynamicIconSize = Math.min(dynamicIconSize, 32);
    setDynamicIconSize(dynamicIconSize);
  };

  useDebouncedResize(getDynamicIconSize, 150);

  return (
    <Flex
      ref={ref}
      direction={direction}
      justifyContent="space-around"
      alignItems="center"
      {...(direction === "column"
        ? {
            h: "100%",
            py: 1,
          }
        : {
            w: "100%",
            px: 1,
          })}
    >
      {conversationInfo && (
        <IconButton
          icon={<TbMessageCircle fontSize={dynamicIconSize} />}
          aria-label="Message"
          variant="ghost"
          color="blue.500"
          onClick={() => setIsMessagingModalOpen(true)}
        />
      )}
      {resumeOpenUrl && (
        <IconButton
          icon={<TbFileText fontSize={dynamicIconSize} />}
          aria-label="Resume"
          isDisabled={!resumeOpenUrl}
          variant="ghost"
          color={resumeOpenUrl ? "blue.500" : "gray.300"}
          onClick={() => {
            if (resumeOpenUrl) {
              window.open(resumeOpenUrl, "_blank", "noopener,noreferrer");
            }
          }}
        />
      )}
      {linkedInUrl && (
        <IconButton
          as="a"
          icon={<TbBrandLinkedin fontSize={dynamicIconSize} />}
          aria-label="LinkedIn profile"
          target="_blank"
          rel="noopener noreferrer"
          href={linkedInUrl ? (linkedInUrl.startsWith("http") ? linkedInUrl : `https://${linkedInUrl}`) : undefined}
          isDisabled={!linkedInUrl}
          variant="ghost"
          color={linkedInUrl ? "blue.500" : "gray.300"}
        />
      )}
      {emailAddress && (
        <IconButton
          icon={<TbSend fontSize={dynamicIconSize - 2} />}
          aria-label="Send Email"
          isDisabled={!emailAddress}
          variant="ghost"
          color={emailAddress ? "blue.500" : "gray.300"}
          onClick={() => {
            if (emailAddress) {
              window.location.href = `mailto:${emailAddress}`;
            }
          }}
        />
      )}
      <Flex justifyContent="center" alignItems="center" minW={`${dynamicIconSize}px`} h={`${dynamicIconSize}px`}>
        {readyToWork ? (
          <BsCheck2Circle fontSize={dynamicIconSize - 2} style={{ color: "green" }} />
        ) : (
          <BsCircle fontSize={dynamicIconSize - 4} style={{ color: "red" }} />
        )}
      </Flex>
      {conversationInfo && (
        <MessagingModal
          conversation={conversationInfo.conversation}
          recipient={{
            recipientId: conversationInfo.recipientId,
            recipientName: conversationInfo.recipientName,
          }}
          isOpen={isMessagingModalOpen}
          onClose={() => setIsMessagingModalOpen(false)}
        />
      )}
    </Flex>
  );
};
