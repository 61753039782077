import { useEffect, useState } from "react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Heading, Text, Image, HStack, Button, Icon, Hide, Center } from "@chakra-ui/react";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { AthleteQuickActions } from "../../../components/AthleteQuickActionIcons";
import { AwesomeHCASpinner } from "../../../components/AwesomeHCASpinner";
import { FullConversation } from "../../../services/messages-api";
import { SearchableAthlete } from "../FindAthletesPage";

interface AthleteFocusCardProps {
  athlete: SearchableAthlete | null;
  conversation: FullConversation | null;
}

export interface ConversationInfo {
  conversation: FullConversation | null;
  isEmpty: boolean;
  recipientId: number;
  recipientName: string;
}

const AthleteFocusCard = ({ athlete, conversation }: AthleteFocusCardProps) => {
  const navigate = useNavigate();
  const [isLoadingAthleteImage, setIsLoadingAthleteImage] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string | null>(null);
  const [conversationInfo, setConversationInfo] = useState<ConversationInfo | null>(null);

  useEffect(() => {
    if (!athlete) return;
    setIsLoadingAthleteImage(true);
    setProfilePictureUrl(null);

    const getProfilePicture = async () => {
      if (!athlete.profilePictureMeta) return;
      const storedFileName = athlete.profilePictureMeta.stored_file_name;
      try {
        const url = await axios.post("/api/file/presigned-open-url", {
          category: "profile-picture",
          remoteFileName: storedFileName,
        });
        setProfilePictureUrl(url.data.presignedUrl);
      } catch (error) {
        setProfilePictureUrl(null);
        console.error("error setting athlete profile picture url", error);
      }
    };

    getProfilePicture();
    setIsLoadingAthleteImage(false);
  }, [athlete]);

  useEffect(() => {
    if (conversation) {
      setConversationInfo({
        conversation: conversation,
        isEmpty: false,
        recipientId: athlete?.athleteId || 0,
        recipientName: athlete?.displayName || "",
      });
    } else {
      setConversationInfo({
        conversation: null,
        isEmpty: true,
        recipientId: athlete?.athleteId || 0,
        recipientName: athlete?.displayName || "",
      });
    }
  }, [conversation, athlete]);

  if (isLoadingAthleteImage) {
    return <AwesomeHCASpinner />;
  }

  if (!athlete) {
    return (
      <Flex flexDirection="column" flex={1} height="100%" justifyContent="center" alignItems="center">
        <Text fontSize="lg" fontWeight="medium">
          Select an athlete to view more information.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="row" gap={3} flex={1} bg="white" p={2} rounded="lg" shadow="md">
      <Flex height="100%" justifyContent={"center"}>
        <AthleteQuickActions
          conversationInfo={conversationInfo}
          baseIconSize={14}
          resumeOpenUrl={""}
          linkedInUrl={""}
          emailAddress={""}
          readyToWork={athlete.readyToWork}
          direction="column"
        />
      </Flex>
      <Flex minWidth="150px" maxWidth="175px" height="100%" rounded="lg">
        {isLoadingAthleteImage ? (
          <AwesomeHCASpinner />
        ) : profilePictureUrl ? (
          <Image
            src={profilePictureUrl}
            alt="Profile Picture"
            width="100%"
            height="auto"
            objectFit="cover"
            rounded="lg"
            loading="eager"
          />
        ) : (
          <Center height={200} width={200} borderRadius="md" bg="white">
            <Icon as={FaUserCircle} fontSize="50px" />
          </Center>
        )}
      </Flex>
      <Flex flexDirection="column" flex={2} height="100%">
        <HStack justifyContent="space-between">
          <Heading size="md">{athlete.displayName}</Heading>
          <Button
            borderRadius="full"
            pl={[0, 6]}
            pr={[1, 5]}
            variant="outline"
            colorScheme="gray"
            size="sm"
            fontWeight="normal"
            onClick={() => navigate(`/athlete/profile/${athlete.athleteId}`)}
          >
            <Hide below="md">View Profile</Hide>
            <Icon ml={1} as={ArrowForwardIcon} transform="rotate(-45deg)" />
          </Button>
        </HStack>
        <Text fontSize={["sm"]}>
          {[athlete.graduationYear, athlete.universityName, athlete.major].filter(Boolean).join(" - ")}
        </Text>
        <Text fontSize={["sm"]}>{[athlete.primarySport, athlete.primaryPosition].filter(Boolean).join(" - ")}</Text>
        <Text fontSize={["sm"]}>
          {athlete.currentLocation && (
            <>
              <strong>Current Location:</strong> {athlete.currentLocation}
            </>
          )}
        </Text>
        <Text fontSize={["sm"]}>
          {athlete.preferredLocation.length > 0 && (
            <>
              <strong>Preferred Locations:</strong> {athlete.preferredLocation.join(", ")}
            </>
          )}
        </Text>
        <Text fontSize={["sm"]}>
          {athlete.preferredEmployment && (
            <>
              <strong>Preferred Employment Type:</strong> {athlete.preferredEmployment}
            </>
          )}
        </Text>
        <Text fontSize={["sm"]}>
          {athlete.preferredWorkEnvironment && (
            <>
              <strong>Preferred Work Environment:</strong> {athlete.preferredWorkEnvironment}
            </>
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

export default AthleteFocusCard;
