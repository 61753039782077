import { useEffect, useState } from "react";

import { Center, Spinner, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { getSpecificProfile } from "../../services/athlete-api";
import { getSpecificProfileImage } from "../../services/file-api";
import { AthleteProfile } from "../athlete";

export function SpecificAthleteProfilePage() {
  const [isValidatingProfile, setIsValidatingProfile] = useState(true);
  const { userId } = useParams();
  const {
    data: profileData,
    isFetching: isProfileFetching,
    isError: isProfileError,
    refetch: refetchProfileData,
  } = useQuery({
    queryKey: ["specificProfileData"],
    queryFn: () => getSpecificProfile(Number(userId)),
  });

  const {
    data: profileImage,
    isFetching: isProfileImageFetching,
    refetch: refetchProfileImage,
  } = useQuery({
    queryKey: ["specificProfileImage"],
    queryFn: () => getSpecificProfileImage(Number(userId)),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (profileData?.id !== Number(userId)) {
      refetchProfileData();
      refetchProfileImage();
    }
    setIsValidatingProfile(false);
  }, [profileData, userId]);

  if (isProfileError) {
    return (
      <Center h="100%">
        <Text color="red.500" fontSize="2xl" fontWeight="bold">
          User does not exist
        </Text>
      </Center>
    );
  }

  if (!profileData || isProfileFetching || isProfileImageFetching || isValidatingProfile) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return <AthleteProfile profileData={profileData} profileImage={profileImage} />;
}
