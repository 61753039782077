import { useState } from "react";

import { ChatIcon } from "@chakra-ui/icons";
import {
  Card,
  Box,
  Avatar,
  CardHeader,
  Heading,
  CardBody,
  VStack,
  HStack,
  Text,
  Stack,
  useBreakpointValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import { UserProfileInfo, UserRole } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import { useAppStore } from "../../app-store";
import { useUniversity } from "../../hooks/useUniversity";
import { MessagesApi } from "../../services/messages-api";
import MessagingModal from "../MessagingModal";

interface ProfileCardProps {
  profileData: UserProfileInfo;
  profileImage: { url: string } | null;
}

const AthleteProfileCard: React.FC<ProfileCardProps> = ({ profileData, profileImage }) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { university } = useUniversity(profileData?.universityId);
  const [isMessagingModalOpen, setIsMessagingModalOpen] = useState(false);

  const { data: conversation } = useQuery({
    queryKey: ["conversation", userInfo?.id, profileData.id],
    queryFn: () => MessagesApi.getConversationByParticipants(userInfo?.id ?? 0, profileData.userId),
    enabled: !!userInfo?.id && !!profileData.userId && userInfo.id !== profileData.userId,
  });

  const isRecruiter = userInfo?.role === UserRole.RECRUITER || userInfo?.role === UserRole.COMPANY_ADMIN;
  const canMessage = (isRecruiter || userInfo?.role === UserRole.ADMIN) && userInfo?.id !== profileData.userId;

  if (!profileData) {
    return null;
  }

  let profileName = profileData?.displayName ? profileData?.displayName : "Athlete";

  if (isRecruiter) {
    profileName = profileData?.displayName ? profileData?.displayName.split(" ")[0] : "Athlete";
  }

  return (
    <>
      <Card size="lg" w="full" overflow={"hidden"}>
        <Box bg="blackAlpha.300" w="full" h={["60px", 120, 120]} alignContent={"flex-end"} px={6}>
          <HStack justifyContent={"space-between"}>
            <Avatar size={["xl", "2xl"]} src={profileImage?.url} bg="brand.primary" top={[5, 12]} boxShadow="lg" />
          </HStack>
        </Box>
        <CardHeader paddingBottom={1} paddingTop={16}>
          <HStack justifyContent={"space-between"}>
            <Heading size="xl">{profileName}</Heading>
            <Flex h="100%" justifyContent={"flex-end"} alignItems={"flex-start"}>
              {canMessage && (
                <Button
                  rightIcon={<ChatIcon fontSize={24} color="brand.primary" />}
                  bg="gray.100"
                  size="lg"
                  p={4}
                  _hover={{ border: "1px solid", borderColor: "brand.primary" }}
                  onClick={() => setIsMessagingModalOpen(true)}
                >
                  <Text fontSize={18} color="slate.500" mr={2}>
                    Contact
                  </Text>
                </Button>
              )}
            </Flex>
          </HStack>
        </CardHeader>
        <CardBody paddingTop={0}>
          <VStack gap={0} w="full" alignItems="flex-start">
            <Stack direction={["column", "row"]} w="full">
              <Heading size="md" color={"brand.primary"}>
                {profileData?.athleticLevel} {profileData?.primarySport}
              </Heading>
              {profileData?.athleticAchievements
                ?.slice(0, 3)
                .map((item) => (
                  <Text
                    size="md"
                    key={item.id}
                    color={"blackAlpha.500"}
                  >{`${isMobile ? "" : "| "} ${item.achievement}`}</Text>
                ))}
            </Stack>
            <Text mt={[2]} size="sm">
              {university?.universityName}
            </Text>
          </VStack>
        </CardBody>
      </Card>
      <MessagingModal
        isOpen={isMessagingModalOpen}
        onClose={() => setIsMessagingModalOpen(false)}
        recipient={{
          recipientId: profileData.id,
          recipientName: profileName,
        }}
        conversation={conversation ?? null}
      />
    </>
  );
};

export default AthleteProfileCard;
