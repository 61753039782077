import React, { useEffect, useState } from "react";

import { Box, Card, CardHeader, CardBody, Heading, SimpleGrid, Badge, Text, HStack, VStack } from "@chakra-ui/react";

import { MessageParticipant } from "../../../../server/db/schema";
import { useAppStore } from "../../app-store";
import { FullConversation } from "../../services/messages-api";
import MessagingModal from "../MessagingModal";

interface MessageTileProps {
  conversations: FullConversation[];
}

const MessageTile: React.FC<MessageTileProps> = ({ conversations }) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [isMessagingModalOpen, setIsMessagingModalOpen] = useState(false);
  const [activeConversation, setActiveConversation] = useState<FullConversation | null>(null);
  const totalUnreadMessages = conversations.reduce(
    (acc, conversation) =>
      acc +
      conversation.messages.reduce(
        (acc, message) => acc + (!message.is_read && message.sender_id !== userInfo?.id ? 1 : 0),
        0,
      ),
    0,
  );

  useEffect(() => {
    if (activeConversation) {
      const foundConversation = conversations.find((conversation) => conversation.id === activeConversation.id);
      if (foundConversation) {
        setActiveConversation(foundConversation);
      }
    }
  }, [conversations]);

  const onConversationClick = (conversationId: string) => {
    setActiveConversation(conversations.find((conversation) => conversation.id === conversationId) || null);

    setIsMessagingModalOpen(true);
  };

  if (conversations.length === 0) {
    return null;
  }

  // TODO: I neeed to sort the messages by last updated.

  return (
    <Box w="full">
      <Card overflow="hidden" boxShadow="md" size="lg" w="full">
        <CardHeader p={4} bg="brand.primary" textColor="white">
          <Heading size="md" textAlign="center">
            {totalUnreadMessages ? `${totalUnreadMessages} Unread Messages` : "Messages"}
          </Heading>
        </CardHeader>
        <CardBody p={4}>
          <SimpleGrid columns={1} spacing={2}>
            {conversations.map((conversation) => {
              const totalUnread = conversation.messages.reduce(
                (acc, message) => acc + (!message.is_read && message.sender_id !== userInfo?.id ? 1 : 0),
                0,
              );
              const otherParticipant: MessageParticipant | undefined = conversation.participants.find(
                (participant) => participant.user_id !== userInfo?.id,
              );

              const fromText =
                otherParticipant?.user.role === "athlete"
                  ? otherParticipant.universityName
                  : otherParticipant?.user.role === "company_admin"
                    ? otherParticipant.companyName
                    : otherParticipant?.user.role === "recruiter"
                      ? otherParticipant.companyName
                      : otherParticipant?.user.role === "admin"
                        ? "Her Competitive Advantage"
                        : "Unknown";

              const lastMessage = conversation.messages[conversation.messages.length - 1];
              const lastMessageDate = lastMessage ? new Date(lastMessage.created_at) : null;
              const today = new Date();
              const messageTimeString = lastMessageDate
                ? lastMessageDate.toDateString() === today.toDateString()
                  ? lastMessageDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
                  : lastMessageDate.toLocaleDateString([], { month: "short", day: "numeric" })
                : "";

              const isAdmin = otherParticipant?.user.role === "admin";

              return (
                <HStack
                  key={conversation.id}
                  w="full"
                  onClick={() => onConversationClick(conversation.id)}
                  cursor="pointer"
                  p={2}
                  borderWidth="1px"
                  borderRadius="md"
                >
                  <VStack alignItems="flex-start" flex={1}>
                    <Heading size="sm" noOfLines={1}>
                      {otherParticipant?.displayName}
                    </Heading>
                    {isAdmin ? (
                      <Text fontWeight="bold" color="purple" fontSize="xs" noOfLines={1}>
                        Her Competitive Advantage
                      </Text>
                    ) : (
                      <Text fontSize="xs" noOfLines={1}>
                        {fromText}
                      </Text>
                    )}
                  </VStack>
                  <VStack alignItems="flex-end">
                    <Text fontSize="sm" noOfLines={1}>
                      {messageTimeString}
                    </Text>
                    {totalUnread > 0 && (
                      <Badge borderRadius="full" bg="red.500" color="white" px={2}>
                        {totalUnread}
                      </Badge>
                    )}
                  </VStack>
                </HStack>
              );
            })}
          </SimpleGrid>
        </CardBody>
      </Card>
      <MessagingModal
        isOpen={isMessagingModalOpen}
        onClose={() => setIsMessagingModalOpen(false)}
        conversation={activeConversation}
        recipient={{
          recipientId:
            activeConversation?.participants.find((participant) => participant.user_id !== userInfo?.id)?.user_id || 0,
          recipientName:
            activeConversation?.participants.find((participant) => participant.user_id !== userInfo?.id)?.displayName ||
            "",
        }}
      />
    </Box>
  );
};

export default MessageTile;
